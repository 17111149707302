<template>
  <div class="setting">
    <header-nav :title="headerTitle" />
    <ul>
      <li>
        <div class="item">
          <van-icon name="manager" />
          <span>用户中心</span>
        </div>
      </li>
      <li>
        <div class="item" @click="$router.push('/about')">
          <van-icon name="smile" />
          <span>关于我们</span>
        </div>
      </li>
    </ul>

    <router-view v-slot="{ Component }">
      <transition name="slide">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
<script>
import HeaderNav from '@/components/header/HeaderNav'
export default {
  components: {
    HeaderNav
  },
  data () {
    return {
      headerTitle: '设置'
    }
  },

  mounted () {

  },
  methods: {
    goPath (path) {
      this.$router.push(path)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../styles/mixin";

.setting {
  max-width: 750px;
  height: 100%;
  padding-top: 88px;
  margin: 0 auto;

  ul {
    padding: 15px;
    @include RCSB;
    li {
      width: 50%;
      padding: 15px;
      .item {
        @include CCC;
        background: #fff;
        border-radius: 10px;
        padding: 30px;
        box-shadow: 0 0 8px #f0f0f0;
        i {
          font-size: 70px;
          color: $themeColor;
        }
      }

      span {
        @include sc(28px, $twoFontColor);
        padding-top: 15px;
      }
    }
  }
}
.slide-enter-active, .slide-leave-active {
  transition: all 0.3s;
}
.slide-enter, .slide-leave-to {
  transform: translate3d(100%, 0, 0);
}
</style>
